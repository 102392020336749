<template>
    <div>
        <div  :style="{width:'100%', height:'tabHeight','background-color': '#fff','overflow-y':'auto','display': 'flex'}">         
            <div style="width:100%;border-top:1px dashed #ccc;min-width:1050px; ">
                <div v-for="(item,index) in gpArr" :key="index"  >
                    <div :style="{'display':'flex','border-bottom':'1px dashed #ccc','align-items':'center','width':'100%','height': gpArr.length==4?divHigh4:gpArr.length==3?divHigh3:gpArr.length==2?divHigh2:divHigh}">    
                        <div v-for="(it,ind) in tabData.filter(item2=>item2.GPNO==item.GPNO)" :key="ind" style="display:flex;flex-wrap:wrap">
                            <div  :style="{'border-radius':'10px','margin-left':it.MGL,'display':'flex','flex-direction':'column'}" class="divCss">
                                <el-link :disabled="it.ISOK=='N'" :underline="false" @click="linkUrl(it.NODE)" >
                                    <div style="line-height:65px;height:65px;text-align:center">
                                        <i v-if="it.ISQRY=='Y'" class="iconfont icon-gaojichazhao2" style="font-size:36px;color:#5cadee"></i>
                                        <i v-else :class="it.FICON" style="font-size:36px;color:#5cadee"></i>
                                    </div>
                                    <div style="line-height:35px;height:35px;text-align:center;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;font-size:10px">{{it.CNAME}} </div>
                                </el-link>
                            </div>
                            <div v-if="it.SUBARR" style="width:100px; ">
                                <div style="line-height:100px;height:100px;text-align:center"><i class="iconfont icon-youjiantou" style="font-size:28px;color:#ccc"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="float:right;min-width:220px;border:1px solid #ccc;overflow-y:auto;">
                <div  style="display:flex;justify-content:space-between;line-height:35px;height:35px;border-left:4px solid #409eff;background:#808695;margin-bottom:6px">
                    <div style="color:#fff;line-height:35px; font-size:14px;margin-right:15px;padding-left:5px ">报表明细</div>
                    <div style="float:right;padding-right:6px" >
                       
                        <el-link :disabled="v_sys=='N'" @click="addRec" :underline="false" ><div style="color:#fff;line-height:25px;border-bottom:1px solid #fff;margin-bottom:10px "> 流程维护</div></el-link>
                    </div>
                </div>
                <span v-for="(item,index) in repArr" :key="index" >
                    <div style="display:flex;line-height:30px;height:32px;padding-left:6px;">
                        <div style="width:30px; "><i class="iconfont icon-a-baobiaoguanlibaobiao" style="color:green;font-size:14px"></i></div>
                        <el-link :underline="false" @click="runRep"><div style="font-size:10px">{{item.REPNM}}</div></el-link>
                    </div>
                </span>
            </div>
        </div>
        <!-- 提示框 -->
        <el-dialog :visible.sync="promptWin" width="450px" append-to-body :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">提示</span>
            </div> 
            <div style="line-height:30px;display:flex">
                <div style="width:40px; "><i class="iconfont icon-tishi1" style="color:#5cadff;font-size:36px"></i></div>
                <div style="font-size:12px;line-height:30px; ">{{prompt}}</div>
            </div>
            <span slot="footer"  >
                <el-button size="small" type="primary" @click="promptWin = false">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 节点资料 -->
        <el-dialog class="pub_dialog" v-dialogDrag  :visible.sync="addWin" :width="tabWidth" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="4vh">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:16px;padding-left:5px;">节点明细</span>
            </div> 
            <div class="one-toolbar"  >
                <span class="spanRow">
                    <i class="iconfont icon-xinzeng" style="margin-right:6px; "></i>
                    <el-link :underline="false" @click="addNode"><div style="color:#fff;line-height:25px;border-bottom:1px solid #fff;margin-bottom:10px "> 新增节点</div></el-link>
                    <el-select v-model="selparam"  placeholder="请选择列名"   size="small" style="width:150px; margin-left:50px">
                        <span v-for="item in tabCol" :key='item.key' >
                            <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'&&item.key!=='RN'"></el-option>
                        </span>
                    </el-select>
                    <el-input clearable  :placeholder="input_hold" v-model="txtparam" @keyup.enter.native="qryTabData" size="small" style="width:320px; height:30px; ">
                        <el-select v-model="oper" slot="prepend"   style="width:100px; " >
                            <span v-for="item in operArr" :key='item.IDSEQ'>
                                <el-option :label="item.CNAME" :value="item.SUBNO" ></el-option>
                            </span>
                        </el-select>
                        <el-button size="small" slot="append" icon="el-icon-search" @click="qryTabData" ></el-button>
                    </el-input>
                </span>
            </div>
            <div style="height:86vh;overflow-y:auto;margin-top:-5px">
                <el-table  :data="tabData" 
                    border                            
                    size="small"
                    ref="refdtl"
                    :height="tabHeight" 
                    highlight-current-row>  
                    <el-table-column label="序号" type="index" width="65"></el-table-column>    
                    <el-table-column  v-for="(item ) in tabCol.filter(item=>item.thide=='N')" :key="item.key"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        :sortable="item.key!=='RN'&&item.edcss!=='checkbox'"
                        :min-width="item.width">
                    </el-table-column> 
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="130">
                        <template slot-scope="scope">
                            <el-button size="mini"   type="text" icon="iconfont icon-bianji" @click="dtlEdit(scope.row,scope.$index )">编辑</el-button>
                            <el-button size="mini"    type="text" icon="iconfont icon-shanchu" @click="dtlDel(scope.row,scope.$index )">删除</el-button>    
                        </template>
                    </el-table-column>                        
                </el-table>
            </div>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; " >
                <span style="float:right">
                    <el-button type="info" @click="addWin=false" size="small"  icon="iconfont icon-shanchu"> 退出</el-button>
                </span>
            </div>
        </el-dialog>
        <!-- 明细资料框 -->
        <el-dialog v-dialogDrag  :visible.sync="dtlWin"  width="900px" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{addFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div>
            <Form :model="dtlRow" :label-width="120"  label-position="right" inline  >  
                <span v-for="(item,index) in tabCol" :key="index" >
                    <FormItem  :label="item.title"  :class=" 'item-width'" :prop="item.key"  v-if="item.fhide==='N'">
                        <template slot="label"><span style="color:red;margin-right:4px" v-if="item.key=='NODE'||item.key=='GPNO'">*</span>{{item.title}}</template>                  
                        <el-date-picker type="date"  v-if="item.edcss==='date'" v-model="dtlRow[item.key]"   value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>    
                        <el-input show-word-limit maxlength="10" placeholder="仅能输入10个字" v-else-if="item.key==='NODENM'" v-model="dtlRow[item.key]" style="width:100%" ></el-input>            
                        <el-select filterable clearable v-else-if="item.key=='NODE'||item.key=='PARENTNO' " v-model="dtlRow[item.key]"   style="width:100%">
                            <el-option v-for="(item,index) in formArr" :key="index" :label="item.FRMNM" :value="item.FRMURL"></el-option>
                        </el-select>
                        <el-select filterable clearable v-else-if="item.key=='ARRNO' " v-model="dtlRow[item.key]"   style="width:100%">
                            <el-option v-for="(item,index) in orArr" :key="index" :label="item.CNAME" :value="item.CODE"></el-option>
                        </el-select>
                        <el-select filterable clearable v-else-if="item.key=='GPNO' " v-model="dtlRow[item.key]"   style="width:100%">
                            <el-option v-for="(item,index) in lineArr" :key="index" :label="item.CNAME" :value="item.CODE"></el-option>
                        </el-select>
                        <el-input-number v-else-if="item.edcss==='number'" v-model="dtlRow[item.key]" controls-position="right" style="width:100%"></el-input-number>
                        <el-input  type="text" v-else :disabled="item.disabled==='Y'?true:false "  v-model="dtlRow[item.key]"></el-input>
                    </FormItem>
                </span>
            </Form> 
 
            <span slot="footer"  >
                <el-button type="info" @click="dtlWin=false" size="small"  icon="iconfont icon-shanchu">  取消</el-button>
                <el-button @click="dtlSure" size="small" type="primary" style="margin-left:30px;" icon="iconfont icon-baocun"  > 保存 </el-button> 
            </span>
        </el-dialog>  
        <!-- 提示框 -->
        <el-dialog :visible.sync="repWin" width="400px" append-to-body :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">提示</span>
            </div> 
            <div style="line-height:30px;display:flex;height:40px;display:flex">
                <div style="width:90px; ">单据编号</div>
                <el-select filterable clearable v-if="v_flag=='PO' " v-model="v_ordno"   style="width:100%">
                    <el-option v-for="(item,index) in poArr" :key="index" :label="item.IDNO" :value="item.IDNO"></el-option>
                </el-select>
                <el-input  v-else v-model="v_ordno"> </el-input>
            </div>
            <span slot="footer"  >
                <el-button size="small" type="info" style="margin-right:20px" @click="repWin = false">取 消</el-button>
                <el-button size="small" type="primary" @click="repShow">预 览</el-button>
            </span>
        </el-dialog>      
    </div>
</template>
<script>
 
    import { getTabColOrData,getBaseData } from '../../api/user'
    export default {
        name:'scmoa_chart',
        data () {
            return {
                v_ordno:'',
                v_url:this.$store.state.baseUrl+'sysprivs/getTableRecMany',//api请求路径      
                v_username:this.$cookies.get('v_username'), //api 请求用户
                v_password:this.$cookies.get('v_password') ,//api 请求密码
                v_sys:this.$cookies.get('v_sys'), //系统标志
                repWin:false,
                promptWin:false,
                prompt:'',  
                tabCol:[],
                addWin:false,
                tabData:[],
                dtlWin:false,
                dtlRow:{},
                formArr:[],
                addFlag:'',
                lineArr:[],
                operArr:'',
                selparam:'',
                oper:'like',
                txtparam:'',
                input_hold:'请输入查询的值',
                gpArr:[],
                orArr:[],
                repArr:[],
                v_flag:'PO',
                poArr:[],
            }
        },
        created () {
            this.getTabField() 
            getBaseData(this.$store.state.nologinUrl,'','','','','v_sys_form',' and frmurl is not null').then(res=>{
                this.formArr =res.data.result
            })
            this.getNodeList()
            //操作符
            getBaseData(this.$store.state.nologinUrl,'PARENTNO','15995','','','v_sys_base','').then(res=>{
                this.operArr =res.data.result
            })
            getBaseData(this.$store.state.nologinUrl,'PARENTNO','57603','','','v_sys_base','').then(res=>{
                this.orArr =res.data.result
            })
            
            getBaseData(this.$store.state.nologinUrl,'PARENTNO','57598','','','v_sys_base','').then(res=>{
                this.lineArr =res.data.result
            })
        },
        mounted(){
            this.getGpNum()
            this.getRepList()
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_po_ord','','','','','','',' order by idseq').then((res) => {
                this.poArr =res.data
            })
        },
        computed: {
            tabWidth: function() {
                return (window.innerWidth - 200) + 'px';
            }, 
            tabHeight: function() {
                return (window.innerHeight - 80) + 'px';
            }, 
            divHigh4: function() {
                return (window.innerHeight / 4-22) + 'px';
            }, 
            divHigh3: function() {
                return (window.innerHeight / 3-28) + 'px';
            }, 
            divHigh2: function() {
                return (window.innerHeight / 2-45) + 'px';
            },
            divHigh: function() {
                return (window.innerHeight  -140) + 'px';
            },
        },
        methods: {
            runRep(){
                this.repWin =true
                this.v_flag='PO'
            },
            repShow(){
                if (this.v_ordno ){
                    this.repWin =false 
                //this.repUrl ='http://123plus.xyz:8092/FR/ReportServer?reportlet=/Reports/porep.cpt'+'&p_pono='+row['IDNO']
                //window.open('http://123plus.xyz:8092/webroot/decision/view/report?viewlet=/Reports/porep.cpt'+'&p_pono='+row['IDNO'],'_blank') +'&format=pdf&__embed__=true'
                    window.open('http://123plus.xyz:8092/webroot/decision/view/report?viewlet=/Reports/porep.cpt'+'&p_pono='+this.v_ordno  ,'_blank')
                }else{
                    this.promptWin =true
                    this.prompt ='单据编号不能为空'
                }
            },
            //获取 模块报表（后续要增加 用户对当前报表是否有权限校验）
            getRepList(){
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/getTableRecMany?username='+this.v_username+'&password='+this.v_password,
                    data: {p_key:'modidseq', p_value:'14', p_cond: ' order by sortby',p_table:'V_SYS_MOD_REP'},
                }).then(res=>{ 
                    this.repArr =res.data
                })
            },
            //获取 组行数
            getGpNum(){
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'auth/login/manyResult',
                    data: { p_cond:'select  distinct GPNO from V_MOD_CHART where IDTY=\'OA\'' +' order by gpno',p_cond2:'',p_cond3:'',p_cond4:''},
                }).then(res=>{ 
                    this.gpArr =res.data.result
                })
            },
            qryTabData(){
                let cond_=''
                if (this.selparam){ //列1
                    if (this.oper==='like'){
                        cond_=cond_+' and upper('+this.selparam+') like upper(\'%' + this.txtparam+'%\')'
                    }else{
                        cond_=cond_+' and upper('+this.selparam+')' +this.oper+'upper(\''+this.txtparam+'\')'
                    }
                }
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/getTableRecMany?username='+this.v_username+'&password='+this.v_password,
                    data: {p_key:'IDTY', p_value:'OA',  p_cond:cond_+' order by gpno,sortby',p_table:'V_MOD_CHART'},
                }).then(res=>{ 
                    if (res.data.code=='200'){
                        this.tabData =res.data.result
                    }else{
                        this.promptWin =true
                        this.prompt =res.data.result
                    }
                })
            },
            linkUrl(v_path){
                let that =this
                //判断用户有没权限
                that.$axios({
                    method: 'post',
                    url:that.$store.state.baseUrl+'sysprivs/getTableRecMany?username='+that.v_username+'&password='+that.v_password,
                    data: {p_key:'USERNO',p_value:that.v_username,p_key2:'frmurl',p_value2:v_path,p_table:'V_SYS_USER_PRIV'},
                }).then(res=>{  
               
                    if (res.data.length==0 && that.v_sys=='N'){
                        that.promptWin =true
                        that.prompt ='你的账号没有此表单权限，如有需要请联系管理员'
                    }else{
                        that.$router.push({path: v_path}, () => {})
                    }
                })
            },
            //节点明细
            getNodeList(){
                //获取所有产品记录且初始化分页记录         
                // this.$axios({   
                //     method: 'post',
                //     url:this.$store.state.queryUrl+'?username='+this.v_username+'&password='+this.v_password,
                //     data: {p_key:'IDTY', p_value:'OA',  p_table:'V_MOD_CHART',p_cond:' order by gpno,sortby'},
                // }).then(res=>{           
                //     this.tabData =res.data 
                // })
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'auth/login/manyResult',
                    data: { p_cond:'select a.*,case when b.frmidseq is not null then \'Y\' else \'N\' end ISOK from V_MOD_CHART A,(select * from V_USER_FORM_PRIV where userno=\''+this.v_username+'\') b where a.node=b.frmurl(+) and A.IDTY=\'OA\''+' order by gpno,sortby'  ,p_cond2:'',p_cond3:'',p_cond4:''},
                }).then(res=>{ 
                    this.tabData =res.data.result
                })
            },
            addNode(){
                this.addFlag ='create'
                this.dtlWin =true
                this.dtlRow ={'IDSEQ':'','IDTYNM':'协同管理','IDTY':'OA' }
            },
            dtlSure(){
                if (!this.dtlRow['NODE']){
                    this.prompt='节点名称不能为空'
                    this.promptWin =true
                }else if (this.dtlRow['NODE']==this.dtlRow['PARENTNO']){
                    this.prompt='节点名称和父节点名称不能相同'
                    this.promptWin =true
                }else if (!this.dtlRow['GPNO'] ){
                    this.prompt='行位置不能为空'
                    this.promptWin =true
                }else {
                    this.$axios({
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username+'&password='+this.v_password,
                        data: {p_json:JSON.stringify(this.dtlRow), p_table:'MOD_CHART',frmid:''},
                    }).then(res=>{ 
                        if (res.data.code=='200'){
                            this.dtlWin=false
                            this.getNodeList()
                        }else{
                            this.promptWin =true
                            this.prompt =res.data.result
                        }
                    })
                }
            },
            addRec(){
                this.addWin =true
            },
            dtlEdit(row,index){
                this.dtlWin =true
                this.addFlag ='edit'
                this.dtlRow =JSON.parse(JSON.stringify(row))
            },
            dtlDel(row,index){
                this.$confirm('你确定要删除此行记录吗','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText:'取消',
                    customClass:'messageBox',
                    showClose:false,
                    closeOnClickModal:false,
                    closeOnPressEscape:false,
                    type:'info',
                }).then(()=>{
                    this.$axios({
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username+'&password='+this.v_password,
                        data: {p_key:'idseq',p_value:row['IDSEQ'] ,p_table:'MOD_CHART'},
                    }).then(res=>{   
                        this.getNodeList()    
                    }) 
                }).catch(()=>{})
            },
 
            //获取表列字段
            getTabField(){
                let vm =this
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','56933','fty','HEAD2','','',' order by sortby,lstseq').then((res) => {
                    console.log('len:'+res.data.length)
                    for (let k=0; k<res.data.length; k++){
                        vm.tabCol.push({ //向数组的开头 添加序列号
                            title: res.data[k].title,
                            key: res.data[k].key,
                            align: res.data[k].align,
                            width: res.data[k].width,
                            thide:res.data[k].THIDE,
                            fhide:res.data[k].FHIDE,
                            edcss:res.data[k].EDCSS,
                            disabled:res.data[k].DISABLED,
                            dotnum:res.data[k].DOTNUM,
                        })
                    }
                })
            }
        }
    }
</script>
<style scoped lang="less">
    .item-width  {
        width: 48%;
        height: 35px;
    }
    .divCss{
        border:2px solid #fff;
        width: 100px ;
        height:100px;
    }
    .divCss:hover{
        border:2px solid #5cadee;
        width: 100px ;
        height:100px;
        cursor:pointer;
    }
   .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 1px auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
 
</style>
<style lang="less">
    .el-table {
        th {
            padding: 0 ;
        }
        thead{
            height: 15px !important;
            color:rgb(128,128, 128);
            font-size:12px;
        }
        td {
            padding: 5px ;
            font-size:11px;
        }   
    }
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    .messageBox .el-message-box__content{
        border-top: 1px solid #EBEEF5;
        height: 50px;
    }
    .el-message-box__message p{
        line-height: 30px;
    }
    .messageBox .el-message-box__btns{
        padding: 20px 15px 0;
    }
    .messageBox .el-message-box__header{
        background:#409eff;
    }
    .messageBox .el-message-box__title{
        color:white ;
        font-size:14px;
    }
</style>